export const environment = {
  production: true,
  default: true,
  isLive: false,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
    miravia: {
      name: "Miravia",
      API_ENDPOINT: "https://api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjBhN2IyYmE2ODNkOWQyNTA2NjZhMmM3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjMxNWE5NTI5NjNhN2IwZWI0MGZhMWUyIn0.gZbuqfveroEyBNQM2GRf_8vqVIlFXhLmGbbFMZabuoUUyK5muiNcxxp0jgO1IJ3oNc3jzIirjiROoDVsYC1DEg3i34IZ7oxOy3m5GYleaSGcs3YqFmzXI8ZDQK2IsJkGa4r_QOVt02_WMpowpUbT0_E-G-7ElNet89fWW4pBEN3ZUbFfHsXEjEKs-c9h6-7vNe2aALs02RJlw836QhGNXHzYP1kib3nL2uR-zxnwwWO_2Lgc5YJ_bmx4-InCXe8JWALm03dwLxOt607zO1DsivQTgdBI-lmgM4Zk_qXngv-cDAPq6ibBet1xstI8nIXoFF_5ColF5vlkI1smzF0BjA",
    },
    shopline: {
      name: "Shopline",
      API_ENDPOINT: "https://hubspot-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM4ODY0ZmIyYmQ5ZWZhNjJhMDRkYWI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjM4ODg2NGRhMzE4NTcxOTY4MDQ1YzQyIn0.MHukWb3fgQaHtEnn3XGw36gvCUcVHhhMsjW952Sr2xThMw-B5cRh956MRfZs6fD7Wr5vTE2dAeI-KWOkdPUIy7FMymfONc7jbyVaEdyzplPOwEgasFVKIphkQ1Yd9hgriv9TucuXaANsOIg3ye6EjACcxBUvqB7vMNg12WUK4uGPOA_3n0PeiASfxjD64l9UPEN4lO81-cOd7ML2cbyHtK7sgVgf1phq9FzVh0EhhFM0NaJBn5p5XOLchdGsJk6L1DNI3hWxNlo87gP4EbMbPTZcwf5XjbLZNmBfjiioU6tGF2ILKkviJEOo-Jw1R-2nyBP-IXamcsNU81t1ZA9McQ",
    },
    zoho: {
      name: "Zoho",
      API_ENDPOINT: "https://zoho-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM4ODgyMGFmNTI2MmM3NGZkMDk5Yzg3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjU3OTc5N2JjZDU3N2MzNWVhMGUwYTgyIn0.aIUZBRXZdphHXUpoC0d1LdMlAVLKTpBeBkdtuxiP80RAsYKvV0YsAlC6PltHyqsVHWzIJ9cwm0Sg9Lzx5VweJ4AjXlEa26vWfgQ2StAEV3PqQAsMs7kekH-O0pC0khcVzW1U147Anb1d7CnS5rgQXa5DT7YThuyE6lzmR5QDtqmbFC_c_VQ97MFL-jy64hNQ3opK-MD0lRPH7yohjCQ97gnbqvGGd1xE6zhgVGJZTLuUzeHqrfqlW9ma-PK_jL8MtP6kvnBsrKyBHg8f3wZf8yM6-Gw2kv4vUJq0NHuD_WBf0PsuAOcMtNhJdVYgcLIy5dKd6eDs6pEiMPEPXLUhKQ",
    },
    freshdesk: {
      name: "Freshdesk",
      API_ENDPOINT: "https://freshdesk-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjNhMDE4NDU5M2ZkY2JmMGU3MDg0MWE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjNhMDFiMjIzNWU3MjAzMGVmMDBkMGMyIn0.tWL9d5-_3oBGNwlSpqajT7vq1ErVU17esWaZkNN5ufHf9XvLqF8spwKsQEmNB213Tg0GaGhth_6T55hdHWY5tRN957dxwD2DYQpj6aIE36ZNvvwV_Rvu7ltoeZHMsA3vyqM-wolS1EbHUq68Jai4RCH_5yzTReyw5Ow-nWMJPG8c2eVaR2g0c4N4OAQWrFtAWDs3veEEXXZomML7IDoWfLcPRQ8s4KGRXWt0czogC4EGU-D2dx6Z3artilLOU_n_oBly0cFAfUKPFMB7pvd6yKMeq0Z7ag5tee_0Kdk5h1XUo0K2W9qoDkWP2uwhrEonzqp_JH6bQ31m8QUf-eqGdw",
    },
    jovy: {
      name: "Jovy",
      API_ENDPOINT: "https://prod-jovy-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQyYWE4OGRmNDljN2E1ZjYyMDgyOTk3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQyYWE4YWIxOGI4YTBjY2RmMDY1NzUyIn0.r_ZZ3lbAFAWFesfUicTEzIxtjv0wA2bnsMBwJEa16j4sf_vfVasMhAxcdoKwIw-uhuCV7yxgNCMpVdDk9S7qzfUE0gTfeLkN_7SuGqGl2j_yoJoGZSTjIDPpznweC88f54zlK0QUSfQYU7L1rn2gMJKYQkq5tfQy9LV65BOhqzDaWdSzLbaxXGhk_jCqm9D6-JRFisMF3pK-7pgBR8TAttfc-maItuKECp9ANu1bw5Kgb2huSALoBKI6aPZOhjUm18mEQTyuqHEjShj72Muw-wxslwwUZnQDm7habCUOOrtLQYTuR_PhApHG6TZlTwWImahYsbJfiK9z-qbIm5IxLQ",
    },
    joom: {
      name: "Joom",
      API_ENDPOINT: "https://joom-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQzZDEyNjY2YTE5YzFhODhmMDg4NWY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQzZDEyOGU0ZjQ5MDcwNDI4MDgzZjYyIn0.T_FYSe9RiUYmYaMsmYoO4UjBxN3GfBjQA6zTFcNazgZeWkkihcJHhUd1a9nBCv8stvhrThnDHgnlKgjIofrdDf7n1zSKVWT4UqsciT2D_iyKpaQFSFotRbA5tNX1Jp34m3j2G90by1Es6GRnVaaK4g5UbWXdmGoX0EzUeH7JnCjeegm5GN-Nk5rZl2lHMhaYfmbeZgAIOOtsUsDROfsht27CHSWpvicTacE_ro-zeXxgVFqilm81AjDSbstH34MmST-do7t7QYmwaM5TLYaxoWClMiQ16bOHettxr957kIm8lWXg9Eo9O4xtohhiEmKOdsZhhTF1wB-d1S6Jd8VGBw",
    },
    shopline_catch: {
      name: "Shopline-Catch",
      API_ENDPOINT: "https://shopline-catch-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQzZDJmMDA4ODc0ZDYwMDdhMDg0YWM3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ5OTJhZWU1NmI0NjM5YTZiMGNjNGYyIn0.opy0PBXVUzLNh_B9VIzRD6fBRkrcOVvkk7FB1vpCvb3H71HW2CEzzJH8GQk4vVsLKhVfr5YAdFJiydlB19kda-0tcZl6YtokrXXhDhBTgK1q2HqIOroRDLMqodwCG8o0cJu59BqMxrQBKvhCHKi6KIp2yorwkV-_dak8Uqyt0kKqh5zZPlxWGohxFZJcec0lZuk91GPcxNHhV53z_MCDOgKu4UNZaHUvK0Kv2y2dHaEK7RdtO1ldRZGVgpw36Okf_vNAaO1GYKbvOnZnarpUrCwj5fht-OUheqhrlFgR70i2CGWXe3caREATACv-L8yK1T7M6y4-IgxP1cKAluu34w",
    },
    shopline_ebay: {
      name: "Shopline-Ebay",
      API_ENDPOINT: "https://shopline-ebay-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQzZDM1YjFlNDU0NDk0Y2Q3MDNkMjA3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ5NmRkNGY1NTlmMmJjNzlkMDliMzQyIn0.EEevNGimaFQacV8q-8isa6opFlNy25UHsbcmzPf6lfbfyZVPYfGseU2qjw3f9JYFsmCNQbv-JbCMGqxQIDItMn3NfxvnWn8kgmsFS8sAO51kUD__uB8zsHnJItvsNHUuLJe9fexYC0YeCxVRbJ2Le1cEadxlYLD8q9FJ7bTuy08Yxp6YryFKTEVSoIlU7JSqirtkkpYtaw3AXzg0ZF4qlu0Y4wG1viBElWKPdrjVMOqHAdPs49p7GRg6Eq4SKotbQneET1TwG4GlJGYRjXdxhUYopsKTT3Fp_efiMhfvpkvbFrU6Jm81ISj9HfMrN1aGXkNnO9F0PDRhDOpTaAKcUQ",
    },
    shopline_amazon: {
      name: "Shopline-Amazon",
      API_ENDPOINT: "https://shopline-amazon-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQzZDNmNmRlNTM2Y2U2OGQ3MGU0ZTI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ5NmIxYTZjZGY4MDA3NjNkMGEyMmUyIn0.CvdVNdGjvdLctuqJZwLX9zwMJ0qaHMkepEy3qj9p6xT03nKUOqO1OcttID6PS8sbh6qqiw-lKo8wZzdNsmkqAwrjtwPhO4YbKJVGX1IsA8KIbH-BJthva8oQaD1IA_4rmaHicmfttwksPqg4GFpYHjzYR6Kp5x97ET_FrnIGRI_odICRQ_5wLKsdEwr0FhvhlNaCXfAnVWdtMJDj3mFxpcKAO8ABeR1qNdDc81Uw7qcEbc8a02pEmrEDX1CCs0aBb-NfnTB_LEY-pruUPT73aZglMGL1otCka7uwGQhZu_75rMjLXjJa_z7pBJom-MFu1YXmwQgcTrV_2XE4SugnhA",
    },
    shopify_miravia: {
      name: "Shopify-Miravia",
      API_ENDPOINT: "https://shopify-miravia-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQ0YTNjNWNjM2IwMjExMzNhMDMzZWU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ0YTRlOTZjZTk1YTUwYzdmMDU1YjUyIn0.ocF8DZohY7MIpyc7tFHPonJgif5b7d6pwfFJrDLda66V_wiYdMc6mXqTQ-jWW1-_B-PH5ikDYDTOT2AoVNg_9XeYD9WSrCTR8735Ex_rXJahaI7bFMtgtPfVAQeX54jdl6elj74x9Jczp-8WKsk2c3NKGwy5LljDDSOw0jXmeXOOftZP5opx2S7GqeMkj5CGDBIbdP--u43oaZ_zZ3tWfpOe8YGhbd_kEk2sOLAMzNmjPHQhcrZtvFUAZnQ7RkhS16Wq2jHc_AXjwb2kWQI6HkaARjisXjznC-x2avCqHEUZHb8rbwLzfLyo7ny-5_WfBRKxV0krUSvNe5a2ST99mw",
    },
    manomano: {
      name: "Manomano",
      API_ENDPOINT: "https://manomano-prod-api.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQ2YzkxOTljMDBlNjNlZDQwMDQ1M2Y3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ2Y2M3MTEyYjMwNjVmNjgyMGYzMmMyIn0.rUTI0FNSEIvn3f_Gxgbv6o5V4vSFgOnFjlHxMlm66uUfDGe-s7hn2BkNOwZnR3VVBhjYN_aMV77WfRfhnaPVReSbBEgNu-fpfOzKeHkg-mrBg55rJWq1sSFegH73ckzoMgHN4nNEd1NthF8h7JxMUx2AjQRAj9FiKbjLCCAhqRLm0LpN0lebGG1tMQk660j7blQX6-m4cfvnMajJ-HikS-c_Dm4oBKt5eXRJ5hLWQos_jwtuMsYtzrQUgQud-km1sgyzQIBxwzH7JABHqMae4q820vED_v3B7F7XgGLFilgJwGv7OaDL1V705rUWcqedLYozBV7aSAWW1d7-LscuCg",
    },
    mirakl: {
      name: "Mirakl",
      API_ENDPOINT: "https://seller-middleware-amazonmcf-api-backend.mirakl.net/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQ4MzFhYWFkZTRiNzU1OGI5MGJjZjA3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ4ODJlMzlhNmM1ZjQ5OTQyMGU4NzUyIn0.rI2p0RPX0r9EuaPm_twKq7pKFrzdoMIY41hsPiDi6j4h8pQqhYhmacb8-G6MNBg7-ziHJ0obHDnShbVbtsypqUVXSRFYXxDBAR1CMc8E2UjE7HOGXnFxeSfRvhjEO8ShtpGQKdHzN5hgNDx58pWnF3i-tCX2xJ1big_ubNs3TksvU86ktqWyYKvoQLSgEn1itm_Le-HxCGEqP9ANDpUjAQX-d9h0eAcLQpLa307be95PO0USlR6ICPCBPxTUI9aEItCHjPxDnR6kEPl6Tn63NRwoxrx_L3oOebynN8jcnsyA7caCrtZefN6G0LM4UI_zA3yLNUtwH724Aq78tzHo32ryo17WdvO74PpuxKKWOyuy0KK1oAY0ogpgH3C0DsAn3exNOLM98S_IQn4FMAS_IOFXtxR4whqcaeLedleHydDkB45XXfMbgJBGZX3XwKIca6ni4k2qvn20OcPhDXAR7b1uWJSOahJvqGGgOqE0J7EiC83iFwgGmdJnE_luWRYy0gisP-sAllv9hPFdWi525YOhJzGWMhGhqL4cQQYb3FBfsMe6oFBc4qY8qQcTJes2tE_l5hJoH0FL2NHAK6xobrTxLD9G33PNHuduo_45yHw7XEbaYC_xRNm_8tCRMSaA3U3jHemw1345sS0bu8EBbolKcvuoiwgUPXrhfOTJ7EI",
    },
    michaels: {
      name: "Michael",
      API_ENDPOINT: "https://michaels-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjNkM2U2ZTUyYmQ4NmEwZWI5N2Q3MmQ3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ5YWU1ZjgwMjc4ZGQxM2Y5MTdkZTMyIn0.i8-cDkuNZPM9sWAqzmcwr-ngJjqdWktDfZmoRCUcu40Y1Ad5HVtYD19p0SDRg1q8Pq1ftHjJnV3F6rSw38jPOhuy6lfLbhmlW4VTCDLu-cFoQoU763YqiKuA_xZqWEYbHn_bf4gGVzMNCluYZZTn9szbFkOeKqJUEB7ghwFbG7klyHNzlXJHVKHupx_t-WpKdNoYphYu--LNjAXEwem5vFCLvVWeU_2Qq-74IxQ3buz_1DYUWg2-v6NWlIRdmVv_7dz0E--o1jezf5Jiq3ctyC5ZLRozZMC3YRTZQw_HeX6b7ImJgiYPf72vdVkqINbv6ynlx4I3BxcaxbCRBl124g",
    },
    tiktok: {
      name: "Tiktok",
      API_ENDPOINT: "https://tiktok-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQ0ZmMxMjI1NmU1ZWIxYTlhMGZhYjI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ5YmQyNzM1YjUwYTNlNzkyMDE4OTMyIn0.V7uhp10wCUSsYu2CjPm3CuexvsbEeKePGl4lKyV7yHBpygQ_carmnv5gkJu0YKFSBVzE3an4-eDI-uPdhdKWcfyscx44bvkqIi4noUrRr5OBbMUgfzNuDuLMOy0OlxlQwLeeYiEYFESlM-cGTC8J3mf64pXotaICJJyCarj6Tc4GUsPoVbxX4rb9GXr_FdapBBb41gYyMQw84ouRzLLu2VsKXBk-920SgCLDwgGxpGSFBqhJNk7m4OAwZc8C_RxHIdcXjvUSSR-E2rN5BiTWAVKXTJq_3ElbEV5Pno4St3AxqspByf-UzXXmAZeH1g76LlJNKA2IsOJZ6ogV9CdxsQ",
    },
    mcf_shopfy: {
      name: "MCF-Global",
      API_ENDPOINT: "https://one-shipment-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRmNmQ2NDljYjZhODYzZWZjMDY0NGI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRmODVhNmZkNTdiMzg1YWM0MDI3ODYyIn0.ImnRYISQPr3pUkPXFFNK2UDo5UJkCz6V38BCnmV6BhNEHPMnZpuYbiJ6Sra8zk2bXr_sTEOdP487akkMdBeZGkR8ulBbwTnTHlg1Szjf3olpWHO5m_tawiMtAlpfKvCNjwjFQiDptve6bvMxs0pFUt1HdA9K5CDBXcuZBfmczWpbTFqP0xBog4ewOGmOkWtnph0Kqh-ow_Nz71dja85KdIOy8dlsFvpMdSAFcEhjWLuI0rvIzs2eu9KX2L9l-UIxiF5ldrXojBN5eCNCt47nJ5JqCSaa8x4ex6Kdgi4ODPMZlBqQbm5kXdWf8nZNnvLy3a3SS7k7SklUYvsNtOlKNAeObDbcdxy36WX2Uev8lFRJEAbG6Y9slaDJYd93ie3Hr7H0ePry_bTwppArxtj0OWcxfMmNxp91xy8kdz5_7QEFYWpjj-ai9kt9yqarLFc9jZqxaYeURB86btDX9_rHqOPYPN4EyAG1roxdnt2X2kj7ycslW7aYmJK5T8XjhcSQZ93i5X6ti0FOuZbctf3tNkwp4Gca6zobz3zdtq17R6qt8g6SQV3Ya57-0MA8ti0k1Ycdz6AsDHrLU9BEQba8tXB8jr0J-YVbkIxPY5iz5rwiwOfsPthKdFzDI2hRez4U2VVMPQWbv1buFXjjm-N9zpVdlPH_qNxyaklhEBWTRIU",
    },
    bigcommerce_amazon_ebay: {
      name: "bigcommerce_amazon_ebay",
      API_ENDPOINT: "https://live-bigcom-amazon-ebay-api.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRmYzM0NmExMjIxZWU1NTlhMGUxOGY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRmYzRlYTYxNjNiMWQyZmE4MDk1NDUyIn0.aHP2SDA0OlKcHAiBTG20J8SyFJRvDHYOTB6Q10MvkC4E6kkg3WIPYnRLrFQXVn3aS1Lk3HrZj1JiOFijsWl057ODH1okZ4xF0-s03BKvayK8klVjFW6W-SZFb_eAatYWMRg9YIVMzxqyI6M4YCO9IoTndkE5wYrX3tCUWZ42OEFLLMKzgsqD1spnLZSbT4GiGsKfoQTKICB5DGGePFdjpDpg2kP55FkdIJlmzmACbQxZ6Hx27X6sc_HHsEZFd9o-zloQ-cesrmjSi1YG3dzEHnj6ZVYwWpwGAj68eYWjgh3Q3KuuyUl0psSz-soGTXaY6h2F1fY2KG9sWKQ3W7YVVQ",
    },
    shein: {
      name: "Shein",
      API_ENDPOINT: "https://shein-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjUxNTgyZmUxYWJiMTczN2Y3MDA4ZmI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjUxNTgzMDQxNjE0NmYyNzhiMGZiMGIyIn0.AOI59vFdNgQ7qBg7l0YSq9fmw-bAtpYqmPu4mjv_0XbmQ08b6ZZU7GmAJkb9t7CsHZzJaGIQzOTclRXEnTS9xU4JqtowT2xcRB43idobJHTZHJeNnUUeUAZvq-02FvEEEFlLpngONf5bVu_trvv2B35j0swiE5WWDYLp1GcCL81bD48urvlcMWYqggOvo-3ahBH_sXQHMo3Cia1C8A4bXr_s0X5I2q9wUpri4GuCoLJvxrCPhaXPZf834Db6lNmB8PwiJTnh57B8sTOyuapM5u4ihQzE3bu3vmNcUIEtCAiZzuePW4wh0qIBQFW7AHJdVWyim_ofDQEimIWIQw6tgsCZKNfH2MD75kImUbXoPgCATa5JgCxtuzdhmeHyP9D0fvLAscAEG567zlOYrK1YbVNiUD1kY0BdiIfw4_6ZrlCoLB20MPbP60xcqZVRObd0vmw6vY-j8cqRhdJ2Th359_dv63Iu_7r_9K6-861hSxtHAs3hShu34mYhtfVxLzr-tMe4hLV7z0xYVWYFa8if6hlAru8L0CqLFXToaIeFWW-YhIASJZ5VtwO748Jk6MeJgvAeD9Mm5Tc6z1B8ebXIylv0v6kUWXDNAdQkwbklrYk5BFP5mG0Im0H2aHmf7kaIiH-H28sRJfmUiNGe0rgrixv8tTD_jsroRV9tfJpi22A",
    },
    mirakl_sfcc: {
      name: "MIRAKL-SFCC",
      API_ENDPOINT: "https://seller-middleware-sfcc-api-backend.mirakl.net/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjUxYTNhYTg4MmFmYzFiNGRhMDIzMTI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjUxYTNhYWI0YzlkZTgyNWRhMGFhMTgyIn0.LO13ayX4m3gjjotVnRIFyoi-osAUsRtQfNPn-XlfJ5VgdfTalAnv4Xf-QNZrhF2gv5jidEQTJAXe2CKQpfyTMX0Kfv5jaXD1HLts6I36xxiGahuAo1BPlNwRdtarqtwWNenVWzb1AYTfejLHZHYO61yqq3Bu2mqnaROaLXwehs57Xzrf6ELdlvS3UvvpAEynSCbEKuXEv_3-6V7rFo1YaKTSp4kftdoNygVj6-W6rc2qUds-E2lvcEl0VgwaBZpVwXZFZY6fEy0ZlubxE0Oe4BuTfuGgMzulEqUNAwfeKhCZErHw3gypGesZ1X7JQjBYgQWXYmfeHltXwHAAlXeqiUNbOd0e4lK_OC8oR1CrQF-b584GVSQcPNIWH3ZWJskgODSIOphob77m_JlqEfH2ucVex9NNSuVw5xIH310y4nAM_ZIyJ3XUPAhTPHb1Gs0cpbUsUocOBFlc5NIVSx6ZyYcKOOruclJe6wQsVKbWR67oFN0b4NeMP4CJ-QMqalJpPqFv4puVpmbV0fe6ZV3uJTawK6-osjlZaBDLomxgS-g99C4eugrcyAgCMGsPJq46E7BHcCZy8Jwiz4UQ9JYtvxYvFbJ9j1MTnRLxZCx9dLmgxXSSmteucTCzGGM-npDj4wddiT8q5F-sOCjAc148qK6yHH-Jp_TNbMGZ9zerPvc",
    },
    trendyol: {
      name: "TrendyOL",
      API_ENDPOINT: "https://trendyol-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjUzYTI4Zjk2YTNlOTdkZTViMDZmMmU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjUzYTJhMGNmMGZhN2E5MmI1MDZjYzMyIn0.zksyK7UvkE5qLbvhTey-M8wchYa6UofG2w1TYvNtSO_8C7rAB1iX-KcNjz8zM2vgQKSUJTD54h5bqJ5Mh_wt6B_N0owH-m26hryU0eDWmUULcDprFBAPHNhkbNUARJ1WEr0_bvzCckRZHgwSsvaAVJJEfUo33SO1FUzX0qnlpasMzZF9HrGv4xJTNbixPGJeb9XjUoKTSleiUiWm8bxQEnGl8T8xgz1I2NuqsD7bpT_GYYPM6NwkgrI-8Q3ArkxYAQGjtPPs0GBr6g7lX57kBHE7Q7jQl3s_0ORmVCgBEJYbIII-mDM_5w_vX_pseY-MEQvBaSUgNb7lW6TuarEa23B3U5Q19BO-exAukOBRHXGKcKevnaYHaaDxM_Rz8fvr3EnTiLlwZ6tPcVbHfF0_yBweHa74nN3Uxh52hxBZpbks2Bg9GBDO0QvgNR6t03aoZhlUcch_-8-N0jUBOKBKwKL8y8oDjoeAx0vS-SRv05RUe1lF8RM5KJlQNHZHXw_XC_-z1ssGIiYtTQBekoFzGbTv-oYSGBJrLL9a_mgvzoKPGrumvvSIcCtH6wjh8dwprRAHcQPuL2cycIjuAIkDujnei-vIajcXJXYGqNf2W3C4JKR10YPQ6hfI3C4EfSVO9tLQ4cgQ1orHyl987XI9KCMfXy5wzPwYrUUdfDKI2GM",
    },
    aliexpress: {
      name: "Aliexpress",
      API_ENDPOINT: "https://aliexpress-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjU2ZWYyMGY2NzQyNDlkZWMyMDZmOTI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjU2ZWYzM2Q0Y2Q2ZDZiODdlMDY2NzIyIn0.lkcU20w37FC7uHNXpdlQtzwFC5KdCH0YIX5MFfY_oRar-gj7lj9qfc_BZlJOKIQYwWz8e8StyVFzHz0ZwRfOoJaqTFf67OnFUu4jdcHWdrHjJHBTwvQIaf6XyarvlgvlC3Ti1otI6HFO-kk4kd5z9JIVoZYoFQkCAZBFcTaQ5LCXTgXO2PZ5FMOfiVVS9BvRUg4wvb-1QSSgWN0J7F0TiPS70WGEyQhIF0LtKs23_oxQuFP3Gdc6stubbC5bBDGkcdqVldgbtL27KgYlKqtqZr21B5NWxwXEso91CkVFPn8u4P8WvQjQJmB_JjxeEJbSQAIKPHKvnDrxKznpYBCJ66h_kwQTZoMbRXENobcLMlpAjCF6FUI4al5jJX8CYw6jDYI2IZ9teUhRVtfdFZexqE2DgzTEI41-VZnxLWZKt7IIRqD6gYsx93j8huMqVhKZ19p0t8TH87Otnn84nhJAnWX0uhVmWI1lMAPoFVDfPGR1W7DV_ZUzqZlPI55LV1UJdyS7IijqARVRS2EsdQGHKgRscYmgj37kg9fGeaS3ssxlSpjrXt-R42cBwzh8QZtypP73CJPM_kAv2yrgwUNuvj_t6oFrF3LxJ2dw7XvWV8zUiWXbe9uxJEXpyD9D3Z44-vRt_VRA6brMuQp7jxQTk1iJ7Ry6QF2n3qx2wcDZ-XY",
    },
    mirakl_woocommerce: {
      name: "Mirakl-Woocommerce",
      API_ENDPOINT: "https://mirakl-woocommerce-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjYwNTc1ODk4YWJlYTIwYmFmMDY5NDI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjYwYmRjOTAyNmJjOGUxZjdjMDAwM2QyIn0.fZ7zSe3nKc5rOWUyntoHYzKciBoIYLIz9XSjyoucvYRNq27PvLXOdEPsx3mmfYAvm1WkZV9HFNb6-p6bf5kL1LTSx_ZZVTvb2zuxH8F22YlukW9dZoJ-LtRjgIBAMv4UBvN9GsHCicPv0967pcGD8b-K19uexh2kMIUq3jpZXRiONlneedt2Qzm9692C34uz2xfMCH9qsoJNc1n-vzi2nTDMD0a3dpD-hA7wxX-tVFJAv5Vy6mTjNpWXOurrl6GK775HVJkSdfzAGSa_P-mnj_lwHnZJI-dz_u8f05PLzxk_bCdB8BIGRA1-w_ggh-O_0bBpPZLH3KS71TwRJLQnMlAZDZIlmOSIcm51vuOOYfao1ORh-UArllZVLFvJH5yvWfRB0t8XKuJxHzZ5NbegliCUo0DtGtD6jZUdv6DFJCesHQSr0lV5HS16iFYfchBX8736XHHbzoOW5i1atw0FXG2ocuywt-yAxrfY-vULnVrXYnpmvqODj_PozYgTtlWx7_4_to_T9Funb8wGDxPMArg5xtLkiWp6-5ra38zDW-PdMZkoc6NaY1NQJTi-l27YuJfDGwX1q6k3QjJLK1_eZOxCmirUARK69x8_nbS6xDl5NLZQTgLFX8awdwCnvU_A13qvx6KaVRkD3S2tRZ-VxYBHG4OLLQtB5l1CuvuNp-g",
    },
    tiktok_wix: {
      name: "TIKTOK-WIX",
      API_ENDPOINT: "https://tiktokwix-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjY5OGQ0NDE4MGQwNTE5NTVjMDRiYmU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjY5OGQ0NDM3NDNlZjkzNmE5MGY0NDYyIn0.0XywzPpZAq23cHKnG97fIOeXcKMX2umYmoui4m9wLQAxdU9fyNuhmboBCohnhqC1EzJ6yWICe0ZX55nvDk2YrlzI_u1qClqAnXmUPWsmJMAPj9R_9IxAsQoiOlpZBiSdOReDT6a3fFxoZ7wBm9elXXhT6e_JXJapyw3p-gSi_QiiSTR897L0fJ9MjQu95Rt1a9oeC_98cGKPVhsM4I0k3oFFeP5ht3XBn5tSItgQtmvEJJniXCvDJcqdH-UNEAgGbr3Q62RjbPwBgVD6epOMzJ85GbejaxmVjBT8Mr5s53iDrVXDVJg4dJAL59MOgExkAONaBpjlM3PHUVfL4EORb78aA9khPFIThuJdFp1apg6J3gEeiLt-LZZHKdFPTm6h1zXPL0KGQ_1QbuTJk2BTMql3KBUkZ6TAbByNFjfPiBxS5Xzx38h1AuhggY9v25l5cUcOMd3sXle6TjG-C7Z9tml4_C0nW5686idipaI6dzC2BOTsnDSWM-8VSANO4LNjm1RNncbARU93wNejzYsA4kLrONS02nq5mAYq2Z_y5hRhnkulkJufwRA5w_xVX_yvL4hFtg07Q-vpDw7DVfMeCY3ZqKTLZOeDEAew46NVIzZzrEf1-7VoM7dyM8w6FmzC5qvKFWN4_WRsuYHYq-SHj03ZcGrhRBfqBHSQwr68Psw",
    },
    temu_shopify: {
      name: "TEMU-SHOPIFY",
      API_ENDPOINT: "https://temu-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjczNDUzM2JmNjUwOTk2YTcxMGRjMTQ3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOi8vYXBwcy5jZWRjb21tZXJjZS5jb20iLCJ0b2tlbl9pZCI6IjY3MzQ2MWUxYzVjMzhmNzQxZjA2NDVkMiJ9.hheJSBj9R0jj0VteJhoKW1aC-3e0ak0Idji65bXqVGztbzG_c3X29mIx-qaGbYxt85NDo8s4fpxNRcAGsZDXHzcvjzx36Me9K0wZGNkprHJhAJ5UmPw43FWj73FwgJJocJNrEB1hBsIbMw_hx2J5f7d8_rEr_HuVJ3IOsCoAMTHeKRQNleDuY2uI3US9Fu0c41HeTJRXl42VkSipy9ZflvqOZWhliztA6tTQqh_YyDRtSMXHDFvIID-jZkaf6tpc8zQhO_WiMBZ5BhH7j6SzRlRWIQ7OZlUkqqVBT3CYp5P1lBBqgiIA2ukOXd77qkBtQb6I2pvrZa5nKtaOpDAGnA",
    }
  },
};
/* Bearer is App token for server */
